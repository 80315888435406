


















import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { PostsViewModel } from '../daos/viewmodels/posts-viewmodel'

@Observer
@Component({
  components: {
    'rct-post-pin': () => import('@/modules/recruitment/posts/rct-post-pin.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: false }) isRctAdmin
  @Prop({}) vm!: PostsViewModel
}
